import { AnimatePresence } from "framer-motion"
import React from "react"
import "../../00_fundaments/_main.module.scss"

function Layout({ children }) {
  return (
    <div>
      <AnimatePresence mode="wait">{children}</AnimatePresence>
    </div>
  )
}

export default Layout
